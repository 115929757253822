﻿
.contact-us-section {     
  .contact-us-header {
    margin-bottom: 2rem;

    @media screen and #{breakpoint(medium)} and #{breakpoint(large down)} {
      font-size: 32px;
    }
  }

  .responsive-embed {
    margin-bottom: 0;
    padding-bottom: 55%;
  }
}

.contact-us {
  input[type=text],
  input[type=email],
  textarea {
    margin-bottom: 1.5rem;
  }

  .error-notice {
    display: none;
  }
}

.contact-us-form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button[type=submit] {
    border-radius: 5000px;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    align-content: flex-end;


    .fi-mail {
      font-size: 1.5rem;
      margin-right: 5px;
    }

  }

  .contact-us-file-button {
    background: transparent;
    color: $dark-gray;
  }
}

.contact-us-list {
  list-style-type: none;
  margin: 0;

  li {
    a {
      color: $black;
    }
  }

  li::before {
    margin-right: 2rem;
    font-size: 2rem;
    vertical-align: middle;
    color: $dark-gray;
    font-family: 'FontAwesome';
  }

  // generates icons - change fontawesome content codes below to change icons
  .address::before {
    //content: '\f041';
    //margin-left: 0.4rem;
    //margin-right: 2.3rem;
  }

  .email::before {
    content: '\f003';
  }

  .phone::before {
    content: '\f098';
  }
}

