.downloads-container {
  border-bottom: 1px solid $light-gray;
  padding-bottom: 1rem;
  .img-wrapper {
    display: flex;
    justify-content: center;
  }
  .menu {
    a:hover {
      text-decoration: underline;
    }
    .disabled {
      color: $medium-gray;
    }
  }
}
