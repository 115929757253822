﻿//Videos page stylesheet
.video-content {
  background-color: $callout-background;
  border-radius: $global-radius;
  padding: $global-padding;
  //padding-top: 2rem;
  width: 100%;
}

.video-header {
  color: $black;
  font-weight: bold;
}

.video-player-container {
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;

  .v-player {
    @include breakpoint(small) {
      width: 100%;
    }
    @include breakpoint(medium) {
      width: 700px;
      height: 400px;
    }
    @include breakpoint(large) {
      width: 1000px;
      height: 500px;
    }

  }

}

.video-gallery {
  list-style: none;

  .video-gallery-item {
    margin-bottom: 1rem;
    color: $black;

    &:hover {
    cursor: pointer;
    text-decoration: underline;
    transition: all ease-in-out 0.2s;

      .video-thumbnail {
        box-shadow: 0 0 8px 2px $white;
      }
    }
  }

  .video-thumbnail {
    width: 150px;
    margin: auto;
    transition: all ease-in-out 0.2s;
  }

  .video-caption {
    text-align: center;
    color: $black;
  }
}



