@charset "utf-8";

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
@include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

//Icon glyphs
@import '../foundation-icons/foundation-icons.css';
@import '../foundation-icons/foundation-icons.ttf';

body {
  @include breakpoint(small) {
    background-color: #02724A;
  }
  @include breakpoint(medium) {
    background: url(../img/background-image.jpg) no-repeat;
    background-size: cover;
  }

}

.body-wrapper {
}

//Menu-Hover-Lines
$menu-hover-lines-transition: all 0.4s ease;
$menu-hover-lines-border-width: 3px;

.menu-hover-lines {
  a {
    padding: 0.5rem 0;
    position: relative;
    color: $white;
    font-size: rem-calc(20px);
  }

  li.active > a {
    background-color: transparent;
  }

  a::before,
  a::after {
    height: $menu-hover-lines-border-width;
    position: absolute;
    content: '';
    //transition: $menu-hover-lines-transition;
    background-color: $white;
    width: 0;
  }

  a::before {
    top: 0;
    left: 0;
  }

  a::after {
    bottom: 0;
    right: 0;
  }

  a:hover,
  div.active > a {
   //transition: $menu-hover-lines-transition;

  }

  a:hover::after,
  .active a::after {
    width: 100%;
  }
}


.books-dropdown {
  a::after {
    background-color: transparent;
  }

  ul.menu {
    background-color: #116657;
    a {
      color: $white;
      transition: none;
      font-size: 0.8rem;
    }

    a:hover::before,
    a:hover::after,
    a:hover {
      background-color: transparent;
      transition: none;
      text-decoration: underline;
    }
  }
}

.top-bar-title {
  margin-right: 1rem;
}

.nav-menu-mobile {
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin: 0;
    a {
      color: $white;
    }
  }

  .menu-icon-mobile {
    @include hamburger($white, $white, 20px, 16px, 3px, 3);
    margin-right: 1rem;
  }
}

.title-home-link {
  a {
    padding: 0.8rem 1rem;
    color: $white;
  }
}

.title-header {
  text-align: center;
  text-decoration: underline;

  @include breakpoint(medium down) {
    display: none;
  }

  @include breakpoint(large) {
    color: $white;
    text-align: center;
    margin: 1rem 0;
  }
}

.intro {
  @include breakpoint(small only) {
    font-size: rem-calc(12px);
  }
  display: none;
  padding: 1rem 15%;
}

.main-sections {
  color: $black;

  .section-title {
    color: $black;
  }
  .card-image {
    display: flex;
    justify-content: center;
    img {
      max-height: 100px;
    }
  }
  .section-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .title {

    }

    .nested {
      padding-left: 0.5rem;
    }

  }
}

section {
  color: $black;
  padding: 2rem;
  background: $body-background;
  border-radius: $global-radius;
}

.is-active > a:hover {
  a::before {
    height: $menu-hover-lines-border-width;
    position: absolute;
    content: '';
    //transition: $menu-hover-lines-transition;
    background-color: $white;
    width: 100%;
  }
}
.studies-container {
  min-height: 500px;
}

.studies-table {
  @include breakpoint(large){
    width: 90%;
    margin: auto;
  }
  th {
    text-align: left;
  }
  thead {
    color: $black;
  }
  tr {
    border-bottom: 1px solid $black;;
  }
  td {
  }
  .fi {
    font-size: 20px;
  }
  .format {
    @include breakpoint(medium) {
      text-align: center;
    }
  }
  .date {
    @include breakpoint(small only) {
      font-weight: bold;
    }
  }
  .disabled {
    color: $dark-gray;
    cursor: not-allowed;
  }
}
footer {
  @include breakpoint(medium) {
    padding: 1rem;
  }
}

//Contact Page Stylesheet
@import 'components/contact';
//Video Page Stylesheet
@import 'components/video';
//Books Page Stylesheet
@import 'components/books';
//Downloads Page Stylesheet
@import 'components/downloads';