﻿.book-wrapper {
  background-color: $white;
  color: $black;
  padding: 1rem;
  border-radius: $global-radius;
  @include breakpoint(large) {
    margin-top: 2rem;
  } 
}

.book-nav-container {
  order: 1;

  padding-top: 1rem;

  a.button {
    font-size: rem-calc(14px);
  }

}
.chapters {

  .chapter-link {
    display: block;
    a:hover {
      background-color: $dark-gray;
      color: $white;
    }
  }
  .current-link {
    display: block;
    a, a:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
}


.book-content-container {
  order: 0;
  font-size: rem-calc(12px);
  overflow: auto;
}
.chapter-container {
  margin-bottom: 3rem;
}

.verse {
  color: $primary-color;
  @include breakpoint(medium) {
    padding-left: 1rem;
  }
}

.footnotes {
  font-size: rem-calc(11px);
}

.poem {
  font-family: "Times New Roman", Times, serif;
  margin:auto;    

  @include breakpoint(small) {
    font-size: 1rem;
    width: 100%
  }
  @include breakpoint(medium) {
    font-size: 1rem;
    width: 90%;
  }
  @include breakpoint(large) {
    font-size: rem-calc(18px);
    width: 60%;
  }

  .poem-title {
    font-family: inherit;
    text-decoration: underline;
    text-align: center;
    font-style: italic;
  }
  .poem-content td{
    padding: 0.1rem;
  }
  .poem-line {
    font-style: italic;
    width: 60%;
  }
  .poem-line-odd {
    font-style: italic;
    text-indent: 0.5rem;
  }
  .poem-reference {
    font-size: 80%;
    text-align: right;
  }
}